import React, { useRef } from "react";
import { ColorTheme, ColorThemeOption } from "../../../data/enum/ColorTheme";
import { useScrollObserver } from "../../../hooks/useScrollObserver";
import { animateTitle } from "./animations";
import styles from "./SectionTitle.module.scss";

interface SectionTitleProps {
  titleParts: Array<string>;
  theme?: ColorThemeOption;
  className?: string;
}

const SectionTitle: React.FunctionComponent<SectionTitleProps> = ({
  titleParts,
  theme = ColorTheme.DARK,
  className,
  ...props
}) => {
  const textWrapperRef = useRef<HTMLHeadingElement | null>(null);

  const observerRef = useScrollObserver<HTMLHeadingElement | null>(animateTitle, textWrapperRef, {
    threshold: 0.7,
    triggerOnce: true,
  });

  return (
    <div ref={observerRef}>
      <h2 ref={textWrapperRef} className={`heading-01 ${styles.sectionTitle} ${className} ${styles[theme]}`} {...props}>
        {titleParts.map((titlePart, index) => (
          <div key={`${titlePart}-${index}`} className={styles.copyWrapper}>
            {titlePart}
          </div>
        ))}
      </h2>
    </div>
  );
};

export default SectionTitle;
