import React, { useEffect, useRef, useCallback } from "react";
import styles from "./DetailLine.module.scss";
import { ColorTheme, ColorThemeOption } from "../../../../data/enum/ColorTheme";
import { useInView } from "react-intersection-observer";
import { gsap, Expo } from "gsap";
import { useIsMobile } from "../../../../hooks/useIsMobile";

export interface DetailLineProps {
  variation: "top" | "halfTop" | "left" | "halfLeft"; // TODO: There will be more options in the future
  className?: string;
  dashDetailClassName?: string;
  theme?: ColorThemeOption;
}

const DetailLine: React.FunctionComponent<DetailLineProps> = ({
  className,
  dashDetailClassName = "",
  variation = "left",
  children,
  theme = ColorTheme.DARK,
  ...props
}) => {
  const detailLineWrapperRef = useRef<HTMLDivElement | null>(null);
  const isMobile = useIsMobile();
  const [observerRef, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const animateDetailLine = useCallback(
    (container: HTMLDivElement | null, variation) => {
      if (!container) return;
      gsap.delayedCall(0.25, () => {
        const tl = gsap.timeline({ defaults: { stagger: 0.2, duration: 2.5, ease: Expo.easeOut } });
        const childs = [...container?.children];
        if (!container) return;
        if (variation === "top" || variation === "halfTop") {
          if (isMobile) {
            tl.from(childs, { height: 0 }, 0);
          } else {
            tl.from(childs, { width: 0 }, 0);
          }
        } else {
          tl.from(childs, { height: 0 }, 0);
        }
        tl.to(container, { opacity: 1, duration: 0.25 }, 0.1).set(childs, { clearProps: "all" });
      });
    },
    [isMobile],
  );

  useEffect(() => {
    if (inView) {
      if (!detailLineWrapperRef) return;
      animateDetailLine(detailLineWrapperRef?.current, variation);
    }
  }, [inView, variation, animateDetailLine]);

  return (
    <div ref={observerRef} className={`${styles.detailLine} ${className} ${styles[variation]}`} {...props}>
      <div ref={detailLineWrapperRef} className={`${styles.dashDetailWrapper} ${styles[theme]} ${dashDetailClassName}`}>
        <span className={styles.borderLine} />
        <span className={styles.dashDetail} />
      </div>
      {children}
    </div>
  );
};

export default DetailLine;
