import { gsap } from "gsap";
import { SplitText } from "../../../vendor/gsap/SplitText.js";

gsap.registerPlugin(SplitText);

export const animateTitle = (titleWrapper: HTMLDivElement | null) => {
  if (!titleWrapper) return;
  const children = [...titleWrapper.childNodes];
  const titleTl = gsap.timeline({ paused: true });

  children.forEach((element, index) => {
    if (!element) return;
    const splitTitle = new SplitText(element, {
      type: "words",
      reduceWhiteSpace: false,
    });

    const height = (splitTitle.words[0] as HTMLDivElement).getBoundingClientRect().height;

    const tl = gsap.timeline({ onComplete: () => splitTitle.revert() });
    tl.set(splitTitle.words, { paddingRight: 20, marginRight: -20 });
    tl.set(element, { overflow: "hidden" });
    tl.set(titleWrapper, { css: { visibility: "visible" } });
    tl.from(splitTitle.words, { y: height, stagger: 0.06, duration: 1, ease: "expo" });

    titleTl.add(tl, index * 0.2);
  });

  return titleTl;
};
